import React, { useCallback, useState } from 'react';
import { db } from '../../shared/db';
import './SubscribeForm.css';

export function SubscribeForm() {
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState(null);

    const onSubmit = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();

        const email = event.target['email'].value;
        setLoading(true);

        db.collection('subscribers')
            .add({ email })
            .then(
                () => {
                    setLoading(false);
                    setSuccess(true);
                    setSubmittedEmail(email);
                }
                , () => {
                    setLoading(false);
                    setError(true);
                }
            )
    }, []);

    return <div className="SubscribeForm">
        { isLoading
        ? <span className="SubscribeForm-context">Submitting...</span>
        : null }

        { isError
        ? <span className="SubscribeForm-context">Error. Please, try again later</span>
        : null }

        { !isLoading && !isError && isSuccess
        ? <span className="SubscribeForm-success">Thank you! We'll notify you via <b>{submittedEmail}</b></span>
        : null }

        { !isLoading && !isSuccess
        ? <form action="#" onSubmit={ onSubmit }>
            <div className="SubscribeForm-form">
                <input name="email" required type="email" className="SubscribeForm-email" placeholder="Enter your email address" />
                <button type="submit" className="SubscribeForm-button">
                    Sign up
                </button>
            </div>
          </form>
        : null }
    </div>
}