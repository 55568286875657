import React, { useMemo } from 'react';
import { analytics } from '../shared/analytics';
import './AboutPage.css';
import contact from './contact.svg';
import { ContactForm } from './ContactForm/ContactForm';

export function AboutPage() {
    useMemo(() => {
        analytics().setCurrentScreen('/about');
        analytics().logEvent('screen_view');
    }, []);

    return (
        <div className="AboutPage">

            <div className="AboutPage-content">
                <p className="AboutPage-subtitle">
                    We are a small startup working day and night to let you develop complex applications that will scale with your business.
                </p>

                <p>
                    Contact us via the form below if you have any questions!
                </p>

                <div className="AboutPage-contact">
                    <div className="AboutPage-graph">
                        <img src={contact} alt="Contact form graph"/>
                    </div>

                    <div className="AboutPage-form" >
                        <ContactForm />
                    </div>
                </div>

            </div>
        </div>
    );
}