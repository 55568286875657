import React, { useCallback, useState } from 'react';
import { db } from '../../shared/db';
import './ContactForm.css';

export function ContactForm() {
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState(null);

    const onSubmit = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();

        const name  = event.target['name'].value;
        const email = event.target['email'].value;
        const msg   = event.target['msg'].value;
        setLoading(true);

        db.collection('messages')
            .add({ name, email, msg })
            .then(
                () => {
                    setLoading(false);
                    setSuccess(true);
                    setSubmittedEmail(email);
                }
                , () => {
                    setLoading(false);
                    setError(true);
                }
            )
    }, []);

    return <div className="ContactForm">
        { isLoading
        ? <span className="ContactForm-context">Sending...</span>
        : null }

        { isError
        ? <span className="ContactForm-context">Error. Please, try again later</span>
        : null }

        { !isLoading && !isError && isSuccess
        ? <span className="ContactForm-success">Got it! We'll contact you soon via <b>{submittedEmail}</b></span>
        : null }

        { !isLoading && !isSuccess
        ? <form action="#" onSubmit={onSubmit}>
            <div className="ContactForm-form">
                <input name="name" required type="text" className="ContactForm-input" placeholder="Your name" />
                <input name="email" required type="email" className="ContactForm-input" placeholder="Your email address" />
                <textarea name="msg" rows="5" className="ContactForm-msg" /> 
                <button type="submit" className="ContactForm-button">
                    Send
                </button>
            </div>
          </form>
        : null }
    </div>
}