import React, { useMemo } from 'react';
import { analytics } from '../shared/analytics';
import graph from './graph.svg';
import './HomePage.css';
import { SubscribeForm } from './SubscribeForm/SubscribeForm';


export function HomePage() {
    useMemo(() => {
        analytics().setCurrentScreen('/');
        analytics().logEvent('screen_view');
    }, []);

    return (
        <div className="HomePage">

            <div className="HomePage-logo" >
                <div className="HomePage-subtitle">
                    <b style={{color: '#FFFFFF'}}>dravt<span style={{color: '#9AB8C9'}}>.dev</span></b> lets
                    you develop web&nbsp;applications using clear
                    logical&nbsp;connections and a wide range of
                    integrations
                </div>
            </div>

            <div className="HomePage-one">
                <img src={graph} alt="Application graph" />
            </div>

            <div className="HomePage-form" >
                <div className="HomePage-cta" >
                    Sign up to receive an email when we launch!
                </div>

                <SubscribeForm />
            </div>

        </div>
    );
}
