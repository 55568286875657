import * as firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDXTKYRYmzoSLhyqC6odLh-RHUstPbyNd8",
    authDomain: "dravt-prelaunch.firebaseapp.com",
    databaseURL: "https://dravt-prelaunch.firebaseio.com",
    projectId: "dravt-prelaunch",
    storageBucket: "dravt-prelaunch.appspot.com",
    messagingSenderId: "831145889891",
    appId: "1:831145889891:web:a208cef03b8864de5ae856",
    measurementId: "G-K5ECSDZVZB"
};

firebase.initializeApp(firebaseConfig);

export { firebase };