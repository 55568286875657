import { importMDX } from 'mdx.macro';
import React, { lazy, Suspense } from 'react';
import './IntroPage.css';

const Content = lazy(() => importMDX('./Intro.mdx'))

export function IntroPage() {
    return <div className="IntroPage">
        <Suspense fallback={<div>Loading...</div>}>
            <Content />
        </Suspense>
    </div>
}
