import React from "react";
import { HashRouter as Router, Route, Switch, NavLink } from "react-router-dom";
import { AboutPage } from "./AboutPage/AboutPage";
import './App.css';
import { HomePage } from "./HomePage/HomePage";
import { IntroPage } from "./IntroPage/IntroPage";

export function App() {
    return (<div className="App">
        <Router>
        <header className="App-header">
            <NavLink exact activeClassName="selected" to="/">Home</NavLink>
            <NavLink exact activeClassName="selected" to="/intro">Intro</NavLink>
            <NavLink exact activeClassName="selected" to="/about">About</NavLink>
        </header>

        <section className="App-body">
            <div className="App-content">
                <Switch>
                    <Route path="/about">
                        <AboutPage />
                    </Route>
                    <Route path="/intro">
                        <IntroPage />
                    </Route>
                    <Route path="/">
                        <HomePage />
                    </Route>
                </Switch>
            </div>
        </section>

        </Router>
    </div>);
}